import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import Layout from '@/layout'

export const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('@/page'),
            },
            {
                path: 'course',
                component: () => import('@/page/course'),
            },
            {
                path: 'platform',
                component: () => import('@/page/platform'),
            },
            {
                path: 'grade',
                component: () => import('@/page/grade'),
            },
            {
                path: 'about',
                component: () => import('@/page/about'),
            },
        ]
    }
];

const router = new Router({
    mode: 'history',
    base: '/',
    routes
});

export default router