<template>
    <div>
        <nav-bar/>
        <main>
            <router-view/>
        </main>
        <foot-bar/>
    </div>
</template>

<script>
import NavBar from "@/layout/NavBar";
import FootBar from "@/layout/FootBar";
export default {
    name: "index",
    components: {FootBar, NavBar}
}
</script>

<style scoped>

</style>