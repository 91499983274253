<template>
    <footer>
        <div class="nav-foot">
            <b-container>
                <div class="item">
                    <h4>网站导航</h4>
                    <p><router-link to="/course">课程体系</router-link></p>
                    <p><router-link to="/platform">平台介绍</router-link></p>
                    <p><router-link to="/grade">考级竞赛</router-link></p>
                </div>
                <div class="item">
                    <h4>软件平台</h4>
                    <p><a href="https://community.makerfac.com" target="_blank">编程社区</a></p>
                </div>
                <div class="item">
                    <img src="https://static.makerfac.com/w/qrcode_mp.jpg" height="150" width="150">
                    <p><small>官方微信</small></p>
                </div>
                <div class="item">
                    <h4>行业新闻</h4>
                </div>
                <div class="item">
                    <h4><router-link to="/about" class="text-light">关于我们</router-link></h4>
                </div>
            </b-container>
        </div>
        <div class="copyright text-center">
            <a href="https://beian.miit.gov.cn/" target="_blank" class="text-nowrap">粤ICP备2020101903号-1</a>
            Copyright © 2016-{{new Date().getFullYear()}}
            <a href="/" class="text-secondary text-nowrap">深圳市美科教育文化传播有限公司</a>
            版权所有
        </div>
    </footer>
</template>

<script>
export default {
  name: "FootBar"
}
</script>

<style lang="scss" scoped>
    footer {

        .nav-foot {
            background-color: rgba(0,0,0,.6);
            padding: 3rem 0;

            .container {
                display: flex;
                justify-content: space-between;

                .item {
                    text-align: center;

                    h4 {
                        color: white;
                        line-height: 3rem;
                    }

                    p {
                        color: #6c757d;
                        display: block;
                        font-size: 70%;
                        line-height: 1rem;
                    }
                }
            }
        }

        .copyright {
            background-color: #2a2a2a;
            color: #666;
            padding: 30px 0;
            font-size: 1rem;
        }
    }
</style>