<template>
    <header>
        <b-navbar toggleable="lg" type="dark" variant="faded" fixed="top">
            <b-navbar-brand to="/" class="ml-5">
                <b-img :src="require('@/assets/logo.png')" alt="美科教育" title="美科教育" width="70" height="70"/>
                美科教育
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item class="ml-3" v-for="nav in navs" :key="nav.name" :to="nav.to">{{nav.name}}</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto mr-5">
                    <b-nav-item class="ml-3" link-classes="text-warning btn btn-outline-warning" active-class="text-light" v-for="nav in outLink" :key="nav.name" :href="nav.href" target="_blank">{{nav.name}}</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <b-navbar-toggle target="navbar-aside-collapse">
                <template #default="{ expanded }">
                    <b-icon-list v-if="expanded"/>
                    <b-icon-list v-else/>
                </template>
            </b-navbar-toggle>
            <b-sidebar id="navbar-aside-collapse" title="网站导航" right bg-variant="dark" text-variant="light" shadow
                       backdrop>
                <b-navbar-nav class="p-3">
                    <b-nav-item v-for="nav in navs" :key="nav.name" :to="nav.to">{{nav.name}}</b-nav-item>
                    <b-nav-item v-for="nav in outLink" :key="nav.name" :href="nav.href" target="_blank">{{nav.name}}</b-nav-item>
                </b-navbar-nav>
            </b-sidebar>
        </b-navbar>
    </header>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            navs: [
                {
                    name: '首页',
                    to: '/'
                },
                {
                    name: '课程体系',
                    to: '/course'
                },
                {
                    name: '平台介绍',
                    to: '/platform'
                },
                {
                    name: '竞赛考级',
                    to: '/grade'
                },
                {
                    name: '关于我们',
                    to: '/about'
                },
            ],
            outLink: [
                {
                    name: '编程社区',
                    href: 'https://community.makerfac.com'
                },
                {
                    name: '图形化编程',
                    href: 'https://community.makerfac.com/scratch'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

header {
    height: 96px;

    .bg-faded {
        background-color: rgba(0, 0, 0, .6);
    }

    .navbar {
        .navbar-brand {
            font-size: 2.25rem;
        }
    }
}
</style>